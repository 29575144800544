import { createStore } from "vuex";

export default createStore({
  state: {
    nowClient: 0, //取当前视窗的最大值作为vw
    isLoading: false
  },
  getters: {},
  mutations: {
    upNowClient(context: any, value) {
      context.nowClient = value
    },
    updateLoadingState(context: any, value) {
      context.isLoading = value
    }
  },
  actions: {},
  modules: {

  },
});
